<template>
  <div>
    <vx-card class="tabulator_card">
      <vs-row class="mb-3">
        <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" class="visit-tree-drop">
          <vs-dropdown vs-custom-content vs-trigger-click>
                <a
                  class="a-icon a-iconns ele-inside-text alignment py-1"
                  href.prevent
                  @click="showTreeBox"
                >
                  Hide Coloumn
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect
                    v-model="selected_column"
                    :multiple="true"
                    :options="treeDataHide"
                    placeholder="Hide Column"
                  />
                </vs-dropdown-menu>
              </vs-dropdown>
        </vs-col>
        <vs-col
          vs-w="3"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vs-button color="#0044BA" size="small" @click="HideColumn">
            Hide - Unhide Columns
          </vs-button>
        </vs-col>
        <vs-col vs-w="3" vs-offset="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
          <vs-button
          v-if="show_btn"
          color="#0044BA"
          icon="file_download"
          size="small"
          @click="downloadCvsTable"
          ref="loadableButton"
          id="button-with-loading"
          class="vs-con-loading__container"
          >Download</vs-button
        >
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" class="mb-6">
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          class="mb-6"
        >
          <div v-for="(chip, index) in searchCriteria" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              closable
              @click="removechip(chip)"
              color="#0044BA"
            >
              <b>{{ getChipData(chip) }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <div ref="table2" id="example-table-theme" ></div>
    </vx-card>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
import eventbus from '../components/eventbus';
export default {
   components: {
    Treeselect,
  },
  props: ["visitor_table_details"],
  created(){
    eventbus.$on("close-download-popup", () => {
      this.$vs.loading.close(this.$refs.loadableButton.$el)
    });
  },
  mounted() {
    this.hideDownload()
    console.log("tableData_visitor", this.visitor_table_details);
    this.getVisitorDetails();
    for (let i = 0; i < this.visitor_table_details.length; i++) {
      const element = this.visitor_table_details[i];
      const visit_type = {
        visit: "Visit",
        call: "Call",
        email: "Email",
        whatsapp: "Whatsapp",
      };
      element.type = visit_type[element.type];
      if (element.first_visit_date !== null) {
        element.first_visit_date = moment(element.first_visit_date).format(
          "DD-MMM-YYYY"
        );
      }
      if (element.enrollment_date !== null) {
        element.enrollment_date = moment
          .unix(element.enrollment_date)
          .format("DD-MMM-YYYY");
      }
      if (element.last_visit_date !== null) {
        element.last_visit_date = moment(element.last_visit_date).format(
          "DD-MMM-YYYY"
        );
      }
      if (element.created_at !== null) {
        element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
      }
      if (element.last_call !== null) {
        element.last_call = moment
          .unix(element.last_call)
          .format("DD-MMM-YYYY");
      }
      if (element.next_call !== null) {
        element.next_call = moment
          .unix(element.next_call)
          .format("DD-MMM-YYYY");
      }
      if (element.mhp_date !== null) {
        element.mhp_date = moment.unix(element.mhp_date).format("DD-MMM-YYYY");
      }
      Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
      Object.assign(element, { inf: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
    }
  },
  data() {
    return {
      show_btn: false,
      searchCriteria: [],
      visitor_details: [],
      selected_column: [],
      treeDataHide: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "Referee",
              label: "Referee",
              children: [
                {
                  id: "referee_fam_lam.identity",
                  label: "Referee CanId"
                },
                {
                  id: "referee_name",
                  label: "Referee Name"
                },
                {
                  id: "referral_date",
                  label: "Referral Date"
                },
                {
                  id: "referee_level",
                  label: "Referee Level"
                },
                {
                  id: "referee_cm",
                  label: "Referee CM"
                },
                {
                  id: "referee_sr",
                  label: "Referee SR"
                },
                {
                  id: "referee_course",
                  label: "Referee Course"
                },
                {
                  id: "referee_enrolled",
                  label: "Referee Enrolled"
                },
                {
                  id: "referee_enrolled_date",
                  label: "Referee Enrolled Date"
                },
                {
                  id: "referee_amount_received",
                  label: "Referee Amount Received"
                },
                {
                  id: "referee_agreed_fee",
                  label: "Referee Agreed Fee"
                },
                {
                  id: "referee_amount_due",
                  label: "Referee Amount Due"
                },
                {
                  id: "referee_visit_status",
                  label: "Referee Visit Status"
                },
                {
                  id: "referee_ef_status",
                  label: "Referee EF Status"
                },
                {
                  id: "referee_first_visit_date",
                  label: "Referee First Visit Date"
                },
                {
                  id: "referee_last_visit_date",
                  label: "Referee Last Visit Date"
                },
                {
                  id: "referee_last_call_date",
                  label: "Referee Last Call Date"
                },
                {
                  id: "referee_next_call_date",
                  label: "Referee Next Call Date"
                },
                {
                  id:"referee_created_at",
                  label:"Referee Created"
                },
                {
                  id: "referee_fam_lam.first_source",
                  label: "Referee FAM"
                },
                {
                  id: "referee_fam_lam.last_source",
                  label: "Referee LAM"
                },
                {
                  id: "referee_mhp_date",
                  label: "Referee MHP Date"
                },
                {
                  id: "inf",
                  label: "Referee Info"
                }
              ],
            },
            {
              id: "Referer",
              label: "Referer",
              children: [
                {
                  id: "referrer_fam_lam.identity",
                  label: "Referer CanId"
                },
                {
                  id: "referrer_name",
                  label: "Referer Name"
                },
                {
                  id: "referrer_level",
                  label: "Referer Level"
                },
                {
                  id: "referrer_cm",
                  label: "Referer CM"
                },
                {
                  id: "referrer_sr",
                  label: "Referer SR"
                },
                {
                  id: "referrer_course",
                  label: "Referer Course"
                },
                {
                  id: "referrer_enrolled",
                  label: "Referer Enrolled"
                },
                {
                  id: "referrer_enrolled_date",
                  label: "Referer Enrolled Date"
                },
                {
                  id: "referrer_amount_received",
                  label: "Referer Amount Received"
                },
                {
                  id: "referrer_agreed_fee",
                  label: "Referer Agreed Fee"
                },
                {
                  id: "referrer_amount_due",
                  label: "Referer Amount Due"
                },
                {
                  id: "referrer_visit_status",
                  label: "Referer Visit Status"
                },
                {
                  id: "referrer_ef_status",
                  label: "Referer EF Status"
                },
                {
                  id: "referrer_first_visit_date",
                  label: "Referer First Visit Date"
                },
                {
                  id: "referrer_last_visit_date",
                  label: "Referer Last Visit Date"
                },
                {
                  id: "referrer_last_call_date",
                  label: "Referer Last Call Date"
                },
                {
                  id: "referrer_next_call_date",
                  label: "Referer Next Call Date"
                },
                {
                  id:"referrer_created_at",
                  label:"Referer Created"
                },
                {
                  id: "referrer_fam_lam.first_source",
                  label: "Referer FAM"
                },
                {
                  id: "referrer_fam_lam.last_source",
                  label: "Referer LAM"
                },
                {
                  id: "referrer_mhp_date",
                  label: "Referer MHP Date"
                },
                {
                  id: "info",
                  label: "Referer Info"
                }
              ],
            },
          ],
        },
      ],
    };
  },
  watch: {
    visitor_table_details(val) {
      this.visitor_details = val;
      console.log("visitor_value", val);
      this.getVisitorDetails();
      for (let i = 0; i < this.visitor_details.length; i++) {
        const element = this.visitor_details[i];
        const visit_type = {
          visit: "Visit",
          call: "Call",
          email: "Email",
          whatsapp: "Whatsapp",
        };
        element.type = visit_type[element.type];
        if (element.first_visit_date !== null) {
          element.first_visit_date = moment(element.first_visit_date).format(
            "DD-MMM-YYYY"
          );
        }
        if (element.enrollment_date !== null) {
          element.enrollment_date = moment
            .unix(element.enrollment_date)
            .format("DD-MMM-YYYY");
        }

        if (element.last_visit_date !== null) {
          element.last_visit_date = moment(element.last_visit_date).format(
            "DD-MMM-YYYY"
          );
        }
        if (element.created_at !== null) {
          element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
        }
        if (element.last_call !== null) {
          element.last_call = moment
            .unix(element.last_call)
            .format("DD-MMM-YYYY");
        }
        if (element.next_call !== null) {
          element.next_call = moment
            .unix(element.next_call)
            .format("DD-MMM-YYYY");
        }
        if (element.mhp_date !== null) {
          element.mhp_date = moment
            .unix(element.mhp_date)
            .format("DD-MMM-YYYY");
        }
        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
        Object.assign(element, { inf: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });

      }
    },
    selected_column(val){
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (val == 'All') {
        this.searchCriteria.push('All')
      }else if (val.length != 0 && val != 'All') {
        for (let k = 0; k < val.length; k++) {
          this.searchCriteria.push(val[k])
        }
      }
    }
  },
  methods: {
     hideDownload(){
      const id = localStorage.getItem('user_id')
      if (id == 39 || id == 132 || id == 937 || id == 122) {
        this.show_btn = true
      }
    },
    downloadCvsTable(){
      this.$vs.loading({
        background: "#0044BA",
        color: "white",
        container: this.$refs.loadableButton.$el,
        scale: 0.45
      });
      this.$emit("download-referral-report")
    },
    getVisitorDetails() {
      this.tabulator = new Tabulator(this.$refs.table2, {
        maxHeight: "80vh",
        data: this.visitor_details,
        rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
          }),
        columns: [
            {
              title:"Referrer",
              width: 160,
        columns:[
        {
            title: "About",
            field: "info",
            headerSort: false,
            formatter:"image",
            hozAlign:"center",
            formatterParams:{
              height:"25px",
              width:"35px",
            },
            cssClass: "padding_cell",
            cellClick: this.getReferrerId,
          },
        {
            title: "Referrer CanId",
            field: "referrer_fam_lam.identity",
            headerSort: false,
            sorter: "alphanum",
          },
          {
            title: "Referrer",
            field: "referrer_name",
            headerSort: false,
            sorter: "alphanum",
          },
          {
            title: "Referral Date",
            field: "referral_date",
          },
          {
            title: "Level",
            field: "referrer_level",
          },
          {
            title: "CM SPOC",
            field: "referrer_cm",
          },
          {
            title: "SR SPOC",
            field: "referrer_sr",
          },
          {
            title: "Course",
            field: "referrer_course",
          },
          {
            title: "Enrolled(Yes/No)",
            field: "referrer_enrolled",
          },
          {
            title: "Enrollment Date",
            field: "referrer_enrolled_date",
            headerSort: false,
          },
          {
            title: "Amount Received",
            field: "referrer_amount_received",
            headerSort: false,
          },
          {
            title: "Agreed Fee",
            field: "referrer_agreed_fee",
          },
          {
            title: "Amount Due",
            field: "referrer_amount_due",
          },
          {
            title: "Visit Status",
            field: "referrer_visit_status",
          },
          {
            title: "EF Status",
            field: "referrer_ef_status",
          },
          {
            title: "First Visit Date",
            field: "referrer_first_visit_date",
          },
          {
            title: "Last Visit Date",
            field: "referrer_last_visit_date",
          },
          {
            title: "Created Date",
            field: "referrer_created_at",
          },
          {
            title: "Last Call Date",
            field: "referrer_last_call_date",
            headerSort: false,
          },
          {
            title: "Next Call Date",
            field: "referrer_next_call_date",
            headerSort: false,
          },
          {
            title: "FAM",
            field: "referrer_fam_lam.first_source",
            headerSort: false,
          },
          {
            title: "LAM",
            field: "referrer_fam_lam.last_source",
            headerSort: false,
          },
          {
            title: "MHP Date",
            field: "referrer_mhp_date",
            headerSort: false,
          },
          
        ],
    },
    {
        title:"Referee",
        columns:[
        {
            title: "About",
            field: "inf",
            headerSort: false,
            formatter:"image",
            formatterParams:{
              height:"25px",
              width:"35px",
            },
            cssClass: "padding_cell",
            cellClick: this.getRefereeId,
          },
          {
            title: "Referee CanId",
            field: "referee_fam_lam.identity",
            sorter: "alphanum",
          },
          {
            title: "Referee",
            field: "referee_name",
            frozen: false,
            sorter: "alphanum",
          },
          {
            title: "Level",
            field: "referee_level",
            frozen: false,
          },
          {
            title: "CM SPOC",
            field: "referee_cm",
          },
          {
            title: "SR SPOC",
            field: "referee_sr",
          },
          {
            title: "Course",
            field: "referee_course",
          },
          {
            title: "Enrolled(Yes/No)",
            field: "referee_enrolled",
          },
          {
            title: "Enrollment Date",
            field: "referee_enrolled_date",
            headerSort: false,
          },
          {
            title: "Agreed Fee",
            field: "referee_agreed_fee",
          },
          {
            title: "Amount Received",
            field: "referee_amount_received",
          },
          {
            title: "Amount Due",
            field: "referee_amount_due",
          },
          {
            title: "Visit Status",
            field: "referee_visit_status",
          },
          {
            title: "EF Status",
            field: "referee_ef_status",
          },
          {
            title: "First Visit Date",
            field: "referee_first_visit_date",
          },
          {
            title: "Last Visit Date",
            field: "referee_last_visit_date",
          },
          {
            title: "Created Date",
            field: "referee_created_at",
          },
          {
            title: "Last Call Date",
            field: "referee_last_call_date",
            headerSort: false,
          },
          {
            title: "Next Call Date",
            field: "referee_next_call_date",
            headerSort: false,
          },
          {
            title: "FAM",
            field: "referee_fam_lam.first_source",
            headerSort: false,
          },
          {
            title: "LAM",
            field: "referee_fam_lam.last_source",
            headerSort: false,
          },
          {
            title: "MHP Date",
            field: "referee_mhp_date",
            headerSort: false,
          },
          
        ],
    },
        ]
      });
    },
    getReferrerId(e, cell) {
      let id = cell._cell.row.data.referrer_mwb_id
      console.log("okkkk",e, id);
      if (id != null && id != "") {
        this.openBigPopup(id);
      }else{
        this.$vs.notify({
          title: 'Null Value',
          text: 'Candidate ID Can not be null',
          color: 'dark,'
        });
      }
    },
    getRefereeId(e, cell) {
      let id = cell._cell.row.data.referee_mwb_id
      console.log("okkkk",e, id);
      if (id != null && id != "") {
        this.openBigPopup(id);
      }else{
        this.$vs.notify({
          title: 'Null Value',
          text: 'Candidate ID Can not be null',
          color: 'dark,'
        });
      }
    },
    HideColumn(){
      console.log('object--', this.selected_item);
      this.showAllCols('all')
      if(this.selected_column.length > 0){
        this.selected_column.forEach((selected_item)=>{
          if (selected_item.toString() == 'All') {
            let array = ['referee','referrer']
            for (let k = 0; k < 2; k++) {
              this.treeDataHide[0].children[k].children.forEach((item) =>{
                let val = array[k]
                if(item.id.includes(val) || item.id.includes('inf')){
                  this.tabulator.hideColumn(item.id)
                }
              })
            }
          }
          else if (selected_item.toString() == 'Referee') {
            this.treeDataHide[0].children[0].children.forEach((item) =>{
              if(item.id.includes('referee') || item.id.includes('inf')){
                this.tabulator.hideColumn(item.id)
              }
            })
            this.showAllCols('two')
          }else if(selected_item.toString() == 'Referer'){
            this.treeDataHide[0].children[1].children.forEach((item) =>{
              if(item.id.includes('referr') || item.id.includes('info')){
                this.tabulator.hideColumn(item.id)
              }
            })
            this.showAllCols('one')
          }else{
            for (let k = 0; k < 2; k++) {
              this.treeDataHide[0].children[k].children.forEach((item) =>{
                if(item.id == selected_item){
                  this.tabulator.hideColumn(item.id)
                }
              })
            }
          }
          })
      }else{
        this.showAllCols('all')
      }
    },
    showAllCols(type){
      if (type == 'all' || type == 'one') {
        this.treeDataHide[0].children[0].children.forEach((treeData) => {
          this.tabulator.showColumn(treeData.id)
        });
      }if (type == 'all' || type == 'two') {
        this.treeDataHide[0].children[1].children.forEach((treeData) => {
          this.tabulator.showColumn(treeData.id)
        });
      }
      
      
    },
    showTreeBox() {
      console.log("clicked");
      return this.treeDataHide;
    },
    removechip(chip){
      if (this.selected_column.indexOf(chip) === -1) {
            this.selected_column = [];
            this.treeDataHide[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.selected_column.push(child.label);
              }
            });
          } else {
            this.selected_column.splice(
              this.selected_column.indexOf(chip),
              1
            );
          }
    },
    getChipData(chip){
      var chip_val = chip
      if (chip_val.includes('.')) {
        chip_val = chip_val.substr(chip_val.indexOf('.') + 1);
      }
      if (chip_val.includes('_')) {
        chip_val=chip_val.replace(/_/g," ");
      }
      return chip_val
    }
  },
};
</script>

<style>
.tabulator_card {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.tabulator {
  background-color: #f2f2f2;
}
.padding_cell img{
  padding-inline-start: 10px;
}
.tabulator .tabulator-header {
  background-color: #f2f2f2;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
  background-color: #f2f2f2;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
  background-color: #e4e1e1;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
  background-color: rgb(0, 0, 0);
  color: #f2f2f2 !important;
}
.filling{
  background-color: #FF4757 !important;
}
.a-iconns > .material-icons {
  margin-left: auto;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.a-iconns {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}
.visit-tree-drop > .vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  padding-inline: 5px;
  color: #000000;
  display: flex !important;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #535252ce;
  border-radius: 10px;
  line-height: 35px;
}
</style>